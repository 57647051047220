import React from "react"
import styled from "styled-components";

import Img from "gatsby-image"

const Card = styled.div`
  display: flex;
  overflow: hidden;
  margin: 0 20px 40px;
  min-height: 300px;
  background: #ffffff center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39,44,49,0.03) 1px 3px 8px;
  transition: all 0.5s ease;
  flex: 1 1 300px;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(39,44,49,0.07) 8px 28px 50px, rgba(39,44,49,0.04) 1px 6px 12px;
    transition: all 0.4s ease;
    transform: translate3D(0,-1px,0) scale(1.02);
  }

  &:nth-of-type(6n + 1) {
    @media (min-width: 795px) {
      flex: 1 1 100%;
      flex-direction: row;

      .post-card-image-link {
        position: relative;
        flex: 1 1 auto;
        border-radius: 5px 0 0 5px;
      }

      .post-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .post-card-content {
        flex: 0 1 357px;
      }

      .post-card-content-link {
        padding: 30px 40px 0;
      }

      .post-card-meta {
        padding: 0 40px 30px;
      }

      h2 {
        font-size: 1.6rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.55em;
      }
    }
  }
`

const CardImageLink = styled.a`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`

const CardImage = styled.div`
  width: auto;
  height: 200px;
  background: #c5d2d9 no-repeat center center;
  background-size: cover;
`

const CardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-pack: justify;
`

const CardContentLink = styled.a`
  position: relative;
  flex-grow: 1;
  display: block;
  padding: 25px 25px 0;
  color: #15171a;

  &:hover {
    text-decoration: none;
  }

  p {
    margin: 0px 0px 1.5em;
    font-size: 0.8rem;
  }
`

const Header = styled.div`
  vertical-align: baseline;
  margin: 0px;
  padding: 0px;
  border-width: 0px;

  h2 {
    margin: 1.5em 0px 0.5em;
    line-height: 1.15;
    font-weight: 700;
    margin-top: 0;
    font-size: 1rem;
  }
`

const Slug = styled.span`
  display: block;
  margin-bottom: 4px;
  color: #738a94;
  font-size: 0.6rem;
  line-height: 1.15;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 25px 25px;
`

const ReadDuration = styled.span`
  flex-shrink: 0;
  margin-left: 20px;
  color: #738a94;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

const estimateReadLength = (str) => {
  var matches = str.match(/[\w\d’'-]+/gi);
  var count =  matches ? matches.length : 0;
  return Math.ceil(count/200);
}

export default (props) => {
  return (
    <Card>
      <CardImageLink className="post-card-image-link" href={`/post/${props.post.slug}`}>
        <CardImage className="post-card-image">
          <Img style={{height: "100%", width: "100%", position: "relative", overflow: "hidden"}} fluid={props.post.image.fluid}/>
        </CardImage>
      </CardImageLink>
      <CardContent className="post-card-content">
        <CardContentLink className="post-card-content-link">
          <Header>
            <Slug>{props.post.tags[0]}</Slug>
            <h2>{props.post.title}</h2>
          </Header>
          <p>{props.post.synopsis.synopsis}</p>
        </CardContentLink>
        <Footer className="post-card-meta">
          <ul></ul>
          <ReadDuration>{estimateReadLength(props.post.body.body)} min read</ReadDuration>
        </Footer>
      </CardContent>
    </Card>
  )
}