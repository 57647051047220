import React from "react"
import styled from "styled-components";

import Card from "../components/card";

const MainContainer = styled.div`
  z-index: 100;
  flex-grow: 1;
  position: relative;
  padding: 0 4vw;
`

const CentreCol = styled.div`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`

const ArticleCards = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;

  @media (min-width: 900px) {
    padding-top: 0;
    margin-top: -70px;
  }
`

export default (props) => (
  <MainContainer>
    <CentreCol>
      <ArticleCards>
        {props.posts.map(({ node: post }) => (
          <Card key={post.id} post={post}></Card>
        ))}
      </ArticleCards>
    </CentreCol>
  </MainContainer>
)
