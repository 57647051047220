import React from "react"
import styled from "styled-components";

const NavBar = styled.nav`
  text-align: center;

  div {
    display: inline-block;
  }

  a {
    background: #ffffff;
    color: #000000;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #dddddd;
    margin: 0 4px;
    box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39,44,49,0.03) 1px 3px 8px;
    border-radius: 6px;
    margin-bottom: 5px;
    min-width: 50px;

    .active-link {
      box-shadow: inset 3px 0px 0px 0px #0a0b0c;
    }
  }

  .active-link {
    box-shadow: inset 3px 0px 0px 0px #0a0b0c;
  }

  a:hover:not(.active-link) {
    background-color: #dddddd;
  }
`

export default (props) => {
  var links = [];
  links.push(
    <a key={"link_id_1"} href={"/"} className={1 === props.selected ? "active-link" : ""}>1</a>
  );
  if(props.total > 1) {
    for(var i = 2;i < props.total + 1;i++) {
      links.push(
        <a key={"link_id_" + i} href={"/" + i} className={i === props.selected ? "active-link" : ""}>{i}</a>
      );
    }
  }
  return (
    <NavBar>
      <div>
        {props.selected > 2 && <a href={"/" + (props.selected - 1)}>«</a>}
        {props.selected === 2 && <a href={"/"}>«</a>}
        {links}
        {props.selected !== props.total && <a href={"/" + (props.selected + 1)}>»</a>}
      </div>
    </NavBar>

  )
}